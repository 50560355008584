<template>
  <v-container fluid :class="{ 'pa-6': $vuetify.breakpoint.mdAndUp }">
    <v-row no-gutters align="center" v-if="!showDataOnly">
      <v-col md="3" cols="12" lg="3" align="start">
        <h2>
          <v-icon :color="color" large left>{{ icon }}</v-icon>
          {{ title }} <span v-if="totalCount">({{ totalCount }})</span>
        </h2>
      </v-col>
      <v-col
        md="3"
        cols="12"
        lg="3"
        align="start"
        v-if="expiredContracts.length"
      >
        <v-alert outlined text color="red" prominent dense border="left">
          {{ expiredContracts.length }} Expired contract employees -
          <a
            style="color: red; text-decoration-line: underline"
            @click="expiredEmployees"
          >
            {{ expiredShow ? "Show All" : "Show" }}</a
          ></v-alert
        >
      </v-col>
      <v-col
        md="3"
        cols="12"
        lg="3"
        align="start"
        v-if="sevenDaysContracts.length"
      >
        <v-alert outlined text color="blue" dense prominent border="left">
          {{ sevenDaysContracts.length }} Expiring contract in 7 days -
          <a
            style="color: blue; text-decoration-line: underline"
            @click="getSevenDaysContracts"
          >
            {{ sevenDaysShow ? "Show All" : "Show" }}</a
          >
        </v-alert>
      </v-col>
      <v-col align="end" justify="end" class="flex-grow-1">
        <v-btn
          class="white--text"
          @click="exportFile"
          :loading="exportLoading"
          :color="color"
          >Export
          <v-icon right>mdi-download-outline</v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <v-data-iterator
      class="pt-2"
      :items="items"
      :options="pagination"
      :items-per-page.sync="pagination.rowsPerPage"
      :server-items-length.sync="totalCount"
      :loading="loading"
      :page.sync="pagination.page"
      :footer-props="{
        showFirstLastPage: true,
        showCurrentPage: true,
        itemsPerPageOptions: itemsPerPageOptions,
      }"
    >
      <template v-slot:header v-if="!showDataOnly">
        <v-toolbar
          :prominent="$vuetify.breakpoint.smAndDown ? true : false"
          dark
          :color="color"
          class="mt-4"
          :class="!filterSelected ? 'mb-2' : ''"
        >
          <v-row
            :no-gutters="$vuetify.breakpoint.smAndDown ? true : false"
            align="center"
          >
            <v-col cols="6" sm="6" md="4" class="pa-2">
              <v-text-field
                v-model="pagination.search"
                clearable
                flat
                solo-inverted
                dense
                hide-details
                prepend-inner-icon="mdi-magnify"
                label="Search"
                placeholder="Search"
              ></v-text-field>
            </v-col>
            <v-col cols="6" md="4" class="pa-2">
              <v-select
                v-model="pagination.orderBy"
                flat
                solo-inverted
                dense
                hide-details
                :items="keysForSorting"
                prepend-inner-icon="mdi-sort"
                label="Sort by"
                placeholder="Sort by"
              ></v-select>
            </v-col>
            <v-col cols="6" sm="6" class="text-left pa-2" md="2">
              <v-btn
                depressed
                outlined
                dark
                block
                @click="filterSelected = !filterSelected"
              >
                <v-icon left v-if="$vuetify.breakpoint.mdAndUp"
                  >mdi-filter-variant</v-icon
                >
                {{ filterSelected ? "Close Filters" : "Filters" }}
              </v-btn>
            </v-col>
            <v-col cols="6" sm="6" md="2" class="text-right pa-2">
              <v-btn-toggle v-model="pagination.sortDesc" mandatory>
                <v-btn large depressed :color="color" :value="false">
                  <v-icon>mdi-arrow-up</v-icon>
                </v-btn>
                <v-btn large depressed :color="color" :value="true">
                  <v-icon>mdi-arrow-down</v-icon>
                </v-btn>
              </v-btn-toggle>
            </v-col>
          </v-row>
        </v-toolbar>
        <filter-wrapper
          v-if="filterSelected"
          :filters.sync="filters"
          color="light-blue"
          :model.sync="paginationFilter"
          :loading.sync="loading"
          v-on:applyFilters="applyFilters"
          v-on:resetFilters="resetFilters"
        ></filter-wrapper>
      </template>
      <template v-slot:no-data>
        <v-card class="pa-1" flat>
          <v-alert :color="color" elevation="2" type="info">
            No data available
          </v-alert>
        </v-card>
      </template>

      <template v-slot:loading>
        <v-card class="pa-1" flat>
          <v-toolbar color="grey lighten-3" dense>
            <v-toolbar-title>Loading data...</v-toolbar-title>
          </v-toolbar>
          <v-alert :color="color" elevation="2">
            <v-progress-circular indeterminate color="primary" />
          </v-alert>
        </v-card>
      </template>
      <template v-slot:default="props">
        <v-row>
          <v-col
            v-for="item in props.items"
            :key="item.name"
            class="pa-4"
            cols="12"
            sm="6"
            md="4"
            lg="4"
            xl="3"
          >
            <v-card class="dekra-card">
              <v-card-title class="font-weight-bold py-0">
                <v-row align="center">
                  <v-col cols="10" md="9" class="text-left text-truncate">
                    <v-card-title>
                      {{ limitSize(fullName(item), 22) }}
                    </v-card-title>
                    <v-card-subtitle class="text-left"
                      >#{{ item.psit_id }}
                    </v-card-subtitle>
                  </v-col>
                  <v-spacer></v-spacer>
                  <v-col cols="2" md="3">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          icon
                          large
                          v-bind="attrs"
                          v-on="on"
                          :to="{
                            name: 'show-' + [routeName],
                            params: { id: item.id },
                          }"
                          v-if="show && userCan('employees-show')"
                        >
                          <v-icon>mdi-eye-outline</v-icon>
                        </v-btn>
                      </template>
                      <span>View</span>
                    </v-tooltip>
                  </v-col>
                </v-row>
              </v-card-title>

              <v-divider></v-divider>
              <v-list>
                <v-list-item v-for="(key, index) in filteredKeys" :key="index">
                  <v-list-item-icon v-if="key.icon">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon v-bind="attrs" v-on="on" right
                          >{{ key.icon }}
                        </v-icon>
                      </template>
                      <span>{{ key.text }}</span>
                    </v-tooltip>
                  </v-list-item-icon>
                  <v-list-item-content v-if="key">
                    <v-row
                      justify="start"
                      class="text-left"
                      style="height: 40px"
                      v-if="key.value === 'job_position_name'"
                      no-gutters
                    >
                      {{ item.job_position_name }}, {{ item.job_location }}
                    </v-row>
                    <v-row
                      no-gutters
                      v-if="key.value === 'contract_expiration_date'"
                    >
                      <span
                        :class="checkTheDate(item.contract_expiration_date)"
                      >
                        {{ expContract(item) }} </span
                      ><span
                        class="pl-1"
                        :class="checkTheDate(item.contract_expiration_date)"
                        v-if="item.contract_expiration_date"
                      >
                        (
                        {{ formatDate(item.contract_expiration_date) }} )</span
                      >
                    </v-row>
                    <v-row no-gutters v-if="key.value === 'total_cost'">
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <span v-bind="attrs" v-on="on" class="pr-2">
                            {{ numberWithSpaces(item.total_cost) }} RSD
                          </span>
                        </template>
                        <span
                          >{{ key.text }} <br />Neto:
                          {{ numberWithSpaces(item.neto_salary) }} RSD<br />
                          Bruto: {{ numberWithSpaces(item.gross_salary) }} RSD
                        </span>
                      </v-tooltip>
                    </v-row>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
              <v-divider></v-divider>
              <v-card-actions style="min-height: 44px">
                <v-spacer></v-spacer>
                <v-badge
                  :content="item.new_comments_count"
                  :value="item.new_comments_count"
                  :color="color"
                  v-if="
                    showCommentsButton && userCan('employees-show-comments')
                  "
                  overlap
                >
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        icon
                        v-bind="attrs"
                        v-on="on"
                        @click="
                          commentDialog = true;
                          currentId = item.id;
                          newComments = item.new_comments;
                        "
                        large
                      >
                        <v-icon
                          :color="
                            item.comments_count > 0 ? 'light-blue darken-1' : ''
                          "
                          >mdi-comment-text-multiple
                        </v-icon>
                      </v-btn>
                    </template>
                    <span>Comment</span>
                  </v-tooltip>
                </v-badge>
                <v-tooltip bottom v-if="!item.deleted_at">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      icon
                      large
                      v-bind="attrs"
                      v-on="on"
                      color="red"
                      v-if="userCan('employees-terminate-contract')"
                      @click="openTerminateDialog(item)"
                    >
                      <v-icon>mdi-clipboard-text</v-icon>
                    </v-btn>
                  </template>
                  <span>Terminate Contract</span>
                </v-tooltip>
                <template v-if="item.deleted_at">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        v-if="userCan('employees-delete')"
                        icon
                        class="error--text"
                        large
                        v-bind="attrs"
                        v-on="on"
                        @click="
                          confirmDeleteDialog = true;
                          currentId = item.id;
                        "
                      >
                        <v-icon right color="error"
                          >mdi-trash-can-outline
                        </v-icon>
                      </v-btn>
                    </template>
                    <span>Delete Employee</span>
                  </v-tooltip>
                </template>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
      </template>
    </v-data-iterator>
    <v-dialog v-model="confirmDeleteDialog" persistent max-width="310">
      <v-card>
        <v-card-title class="error headline white--text"
          >Confirm Delete
        </v-card-title>
        <v-card-text class="body-1 pa-3"
          >Are you sure you want to delete this item?
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            text
            outlined
            large
            @click.native="confirmDeleteDialog = false"
          >
            <v-icon size="20" left>mdi-cancel</v-icon>
            Cancel
          </v-btn>
          <v-btn color="error" outlined large @click.native="deleteItem">
            <v-icon size="20" left>mdi-trash-can-outline</v-icon>
            Confirm
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <terminate-contract-dialog
      v-if="showTerminationDialog"
      :color="color"
      :employee-id.sync="currentId"
      :go-to-url="goTo"
      :dialog.sync="showTerminationDialog"
      @dialog-cancel="updateTerminationDialog(false)"
      @dialog-update="updateTerminationDialog"
    ></terminate-contract-dialog>
    <comment-dialog
      v-if="commentDialog"
      :type.sync="type"
      :type-id.sync="currentId"
      :unread-comment-list.sync="newComments"
      :dialog.sync="commentDialog"
      @dialog-cancel="updateCommentDialog(false)"
      @dialog-update="updateCommentDialog"
    ></comment-dialog>
  </v-container>
</template>
<script>
import date from "@/mixins/date";
import permission from "@/mixins/permission";
import string from "@/mixins/string";
import downloadExcel from "@/mixins/downloadExcel";
import Vue from "vue";
import { mapGetters } from "vuex";

export default {
  mixins: [date, permission, string, downloadExcel],
  name: "EmployeeSimpleList",
  components: {
    TerminateContractDialog: () =>
      import("@/components/core/dialogs/TerminateContract"),
    CommentDialog: () => import("@/components/core/dialogs/CommentDialog"),
    FilterWrapper: () => import("@/components/core/filters/FilterWrapper"),
  },
  props: {
    names: {
      type: Array,
      required: true,
    },
    routeName: {
      type: String,
      required: true,
    },
    keyTitle: {
      type: String,
      required: true,
    },
    namespace: {
      type: String,
      required: true,
    },
    goTo: {
      type: String,
      required: false,
    },
    show: {
      type: Boolean,
      default: false,
    },
    showCommentsButton: {
      type: Boolean,
      default: false,
    },
    showActivityButton: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      required: true,
      default: "Title",
    },
    icon: {
      type: String,
      required: true,
      default: "mdi-help",
    },
    showDataOnly: {
      type: Boolean,
      required: false,
      default: false,
    },
    exportTitles: {
      type: Array,
      required: false,
      default: () => [],
    },
  },
  data() {
    return {
      exportLoading: false,
      confirmDeleteDialog: false,
      showTerminationDialog: false,
      commentDialog: false,
      currentId: null,
      newComments: [],
      type: "employees",
      itemsPerPageOptions: [12, 24, 48, -1],
      filterSelected: false,
      expiredShow: false,
      sevenDaysShow: false,
      date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      paginationFilter: {},
    };
  },
  computed: {
    ...mapGetters({
      expiredContracts: "employees/expiredContracts",
      sevenDaysContracts: "employees/sevenDaysContracts",
      filters: "employees/filtersIndex",
      totalCount: "employees/total",
      loading: "employees/loading",
    }),
    items: {
      get() {
        return this.$store.getters[this.namespace + "/items"];
      },
      set() {
        this.$store.dispatch(this.namespace + "/getItems", false);
      },
    },
    pagination: {
      get() {
        return this.$store.getters[this.namespace + "/pagination"];
      },
      set(value) {
        this.$store.dispatch(this.namespace + "/setPagination", value);
      },
    },
    textColor() {
      return this.$route.meta.textColor || "";
    },
    color() {
      return this.$route.meta.color || "";
    },
    filteredKeys() {
      return this.names.filter((key) => key.value !== "new_comments_count");
    },
    keysForSorting() {
      return this.names.filter((key) => key.sortable == true);
    },
  },
  beforeDestroy() {
    this.expiredShow = false;
    this.sevenDaysShow = false;
  },
  async mounted() {
    await this.$store.dispatch("employees/getExpiredContractEmployees", {
      contractType: "expirationDate",
      contractTo: this.$moment().format("YYYY-MM-DD"),
      all: true,
    });
    await this.$store.dispatch("employees/getSevenDaysContracts", {
      contractType: "expirationDate",
      contractTo: this.$moment().add(7, "days").format("YYYY-MM-DD"),
      contractFrom: this.$moment().format("YYYY-MM-DD"),
      all: true,
    });
    this.paginationFilter=JSON.parse(JSON.stringify(this.pagination))
    },
  watch: {
    pagination: {
      handler() {
        this.getData();
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    async getData() {
      await this.$store.dispatch(this.namespace + "/getItems", false);
    },
    checkTheDate(date) {
      if (this.checkIsAfter(date)) {
        return "red--text font-weight-medium";
      } else if (this.checkIs7Days(date)) {
        return "orange--text font-weight-medium";
      }
    },
    deleteItem() {
      this.$store.dispatch("employees/deleteItem", this.currentId).then(() => {
        this.confirmDeleteDialog = false;
        this.currentId = null;
        this.getData();
      });
    },
    fullName(item) {
      return item.first_name + " " + item.middle_name + " " + item.last_name;
    },
    expContract(item) {
      if (item.contract_expiration_date) {
        return this.differenceBetweenDates(item.contract_expiration_date);
      } else {
        return "Indefinitely";
      }
    },
    openTerminateDialog(item) {
      this.currentId = item.id;
      this.updateTerminationDialog(true);
    },

    updateTerminationDialog(dialog) {
      this.showTerminationDialog = dialog;
      this.getData();
    },
    updateCommentDialog(dialog) {
      this.commentDialog = dialog;
      this.getData();
    },
    expiredEmployees() {
      if (!this.expiredShow) {
        this.paginationFilter.contractType = "expirationDate";
        this.paginationFilter.contractFrom = null;
        this.paginationFilter.contractTo = this.$moment().format("YYYY-MM-DD");
        this.expiredShow = true;
        this.applyFilters();
      } else {
        this.resetFilters();
        this.expiredShow = false;
      }
    },
    numberWithSpaces(string) {
      var x = string % 1 != 0 ? parseFloat(string).toFixed(2) : string;
      var parts = x.toString().split(".");
      parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, " ");
      return parts.join(".");
    },
    getSevenDaysContracts() {
      if (!this.sevenDaysShow) {
        this.paginationFilter.contractType = "expirationDate";
        this.paginationFilter.contractFrom =
          this.$moment().format("YYYY-MM-DD");
        this.paginationFilter.contractTo = this.$moment()
          .add(7, "days")
          .format("YYYY-MM-DD");
        this.applyFilters();
        this.sevenDaysShow = true;
      } else {
        this.resetFilters();
        this.sevenDaysShow = false;
      }
    },
    getItemValue(item, name) {
      if (name.includes(".")) {
        let nameArray = name.split(".");
        if (item[nameArray[0]] != null) {
          if (nameArray[2]) {
            return item[nameArray[0]][nameArray[1]][nameArray[2]];
          }
          return item[nameArray[0]][nameArray[1]];
        }
        return "N/A";
      }
      return item[name];
    },
    applyFilters() {
      this.pagination = {
        ...this.pagination,
        ...this.paginationFilter,
      };
    },
    resetFilters() {
      Vue.set(this.paginationFilter, "jobLocations", []);
      Vue.set(this.paginationFilter, "jobPositionNames", []);
      Vue.set(this.paginationFilter, "salaryType", null);
      Vue.set(this.paginationFilter, "salaryFrom", null);
      Vue.set(this.paginationFilter, "salaryTo", null);
      Vue.set(this.paginationFilter, "contractLength", null);
      Vue.set(this.paginationFilter, "terminatedContract", null);
      Vue.set(this.paginationFilter, "terminatedContractTypeId", null);
      Vue.set(this.paginationFilter, "indefiniteContract", null);
      this.pagination = {
        ...this.pagination,
        ...this.paginationFilter,
      };
    },
    exportFile() {
      this.exportLoading = true;
      this.$store.dispatch("employees/exportAllItemsGet").then(() => {
        let allEmployees = this.$store.getters["employees/exportItems"];
        this.exportToExcel(
          this.exportTitles,
          allEmployees,
          this.namespace + ".xlsx"
        );
        this.exportLoading = false;
      });
    },
  },
};
</script>
<style scoped>
.dekra-card {
  border-bottom: 5px solid #039be5;
}

.v-list-item {
  min-height: 59.19px !important;
}

>>> .theme--dark.v-label {
  color: rgb(255, 255, 255);
}
</style>
